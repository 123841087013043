<template>
  <div class="video-play-header">
    <img
      class="back"
      src="~@/assets/img/videoPlay/back.png"
      alt=""
      @click="$router.go(-1)"
    >
    <div>实拍</div>
    <img
      class="home"
      src="~@/assets/img/videoPlay/home.png"
      alt=""
      @click="$router.push('proAllList?shopUuid='+$store.state.shopUuid)"
    >
    <!--$router.push('proAllList')-->
  </div>
</template>

<script>
export default {
  methods: {
    jump() {
      this.$router.replace(localStorage.getItem('fromPath'))
    }
  }
}
</script>

<style lang="less" scoped>
.video-play-header {
  position: fixed;
  width: 100%;
  top: 36px;
  left: 0px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  box-sizing: border-box;
  div {
    width: 60px;
    height: 25px;
    text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    background: linear-gradient(0deg, #8BE73F 0%, #045BFF 0%, #26AAFD 100%);
    border-radius: 13px;
    color:#fff;
  }
  .back,.home{
      width: 24px;
  }
}
</style>
