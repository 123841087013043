<template>
	<div class="video-play-wrapper" style="background-color: rgba(0, 0, 0, 1) !important">
		<div style="background-color: rgba(0, 0, 0, 1) !important">
			<MyHeader />
			<van-swipe :style="'height:' + heightVh + 'px'" vertical v-if="hasVideo" @change="onChange">
				<van-swipe-item v-for="(item, index) in list" :key="index">
					<VideoPlay ref="videos" style="position: relative;" :sources="item.filePath" :is-load="true" :bg-img="item.bgImg">
						<template #footer>
							<div class="footer-content">
								<div @click.stop="addCart" class="add-cart"><img src="~@/assets/img/videoPlay/add-cart.gif" style="width: 100px;height: 52px;" /></div>
								<template>
									<p v-for="(item, index) in item.infoArr" :key="index" v-if="item.state == 0">
										<span>{{ item.label }}:</span>
										<span>{{ item.value }}</span>
									</p>
								</template>
							</div>
						</template>
						<template #right>
							<div class="right">
								<van-image fit="cover" class="home" :src="$store.state.deLogoSquare"  alt="" @click.stop="$router.push('/home?shopUuid='+$store.state.shopUuid)" />
								<p @click.stop="$router.push('/home?shopUuid='+$store.state.shopUuid)">首页</p>
								<img
									v-if="isLike == 1"
									class="heart"
									src="~@/assets/img/videoPlay/heart-fill.png"
									alt=""
									@click.stop="handleLike('reCollection', item.product.uuid)"
								/>
								<img v-else class="heart" src="~@/assets/img/videoPlay/heart-empty.png" alt="" @click.stop="handleLike('collection', item.product.uuid)" />
								<p>喜欢</p>
								<img class="detail" src="~@/assets/img/videoPlay/detail.png" alt="" @click.stop="goProDetail(item.product.uuid)" />
								<p @click.stop="goProDetail(item.product.uuid)">详情</p>
							</div>
						</template>
					</VideoPlay>
				</van-swipe-item>
			</van-swipe>

			<div v-else-if="!hasVideo && list.length == 0" class="video-none">
				<div class="no-name">暂无视频</div>
				<div style="position: absolute; right: 22px; top: 240px">
					<RightControl :prt-uuid="prtUuid(list[randomIndex].product)" :is-like.sync="isLike" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VideoPlay from './components/videoPlay/index.vue';
import MyHeader from './components/Header.vue';
import RightControl from './components/RightControl.vue';
import { Toast } from 'vant';
import { clickPrtAddOne, getFileList, getProOne, addToCart, randomVideo, reCollection, collection } from '@/api';
import { setPageTitle } from '@/utils';
import _ from 'lodash';
export default {
	components: {
		VideoPlay,
		MyHeader,
		RightControl,
	},
	data() {
		return {
			hasVideo: false,
			activeIndex: 0,
			list: [{}],
			isLike: 0,
			prtVideoList: [],
			videoPictureList: [],
			randomIndex: 0,
			widthVw: document.body.clientWidth,
			heightVh: document.body.clientHeight
		};
	},
	async mounted() {
		const query = this.$route.query;
		this.$forceUpdate();
		this.shopUuid = this.$store.state.shopUuid;
		await this.randomVideo();
		this.hasVideo = this.list && this.list.length > 0;
		this.isLike = this.list[this.randomIndex]?.isLike;
		this.$nextTick(() =>{
			if(this.list.length > 0){
				this.list.map((res,i) =>{
					if(i == this.randomIndex){
						this.$refs.videos[i].playVideo()
					}else{
						this.$refs.videos[i].stopVideo()
					}
				})
			}
		})
	},
	deactivated() {
		this.$destroy(this);
	},
	methods: {
		async handleLike(api, uuid) {
			if (api == 'reCollection') {
				await reCollection({ likColl: 1, prtUuid: uuid });
				this.$emit('update:isLike', 0);
			} else {
				await collection({ likColl: 1, prtUuid: uuid });
				this.$emit('update:isLike', 1);
			}
		},
		goProDetail(uuid) {
			this.$router.push({ name: 'ProductDetails', query: { uuid: uuid ,title:this.list[this.randomIndex].product.prtName} });
		},
		prtUuid(product) {
			const uuid = product?.uuid;
			return uuid;
		},
		// 加入购物车
		addCart() {
			if (this.list) {
				let param = {
					num: 1,
					productId: this.list[this.randomIndex].uuid,
					shopUuid: this.list[this.randomIndex].shopUuid,
					userId: this.$store.state.userInfo.uuid // 用户id
				};
				addToCart(param).then(res => {
					if (res.state == 100) {
						Toast.success('成功加入购物车');
					}
				});
			}
		},

		async onChange(index) {
			this.randomIndex = index;
			if((this.list.length - this.randomIndex) == 1){
				this.list = [];
				this.randomIndex = 0;
				await this.randomVideo();
				setPageTitle(this.list[this.randomIndex].product.prtName);
				this.$nextTick(() =>{
					if(this.list.length > 0){
						this.list.map((res,i) =>{
							if(i == this.randomIndex){
								this.$refs.videos[i].playVideo()
							}else{
								this.$refs.videos[i].stopVideo()
							}
						})
					}
				})
			}else{
				setPageTitle(this.list[this.randomIndex].product.prtName);
				this.$nextTick(() =>{
					if(this.list.length > 0){
						this.list.map((res,i) =>{
							if(i == this.randomIndex){
								this.$refs.videos[i].playVideo()
							}else{
								this.$refs.videos[i].stopVideo()
							}
						})
					}
				})
			}
		},

		async randomVideo() {
			let param = {
				shopId: this.shopUuid,
				page:1,
				pageSize:2
			};
			await randomVideo(param).then(res => {
				if (res.state == 100) {
					if (res.items) {
						for (var i = 0; i < res.items.length; i++) {
							let product = res.items[i].product;
							let result = [];
							if (product.isName == 0) {
								result.push({ label: '名称', value: product.prtName, state: product.isName });
							}
							if (product.isType == 0) {
								result.push({ label: '类别', value: product.prtType, state: product.isType });
							}
							if (product.isCode == 0) {
								result.push({ label: '货号', value: product.prtCode, state: product.isCode });
							}
							if (product.isChiCun == 0) {
								result.push({ label: '体积', value: `${product.prtLong}cm*${product.prtWide}cm*${product.prtHigh}cm`, state: product.isChiCun });
							}
							if (product.isSpecs == 0) {
								result.push({ label: '规格', value: product.prtSpecs, state: product.isSpecs });
							}
							if (product.isContent == 0) {
								result.push({ label: '含量', value: product.prtContent, state: product.isContent });
							}
							if (product.isHairNum == 0) {
								result.push({ label: '发数', value: product.prtHairNum + '发', state: product.isHairNum });
							}
							if (product.isInch == 0) {
								result.push({ label: '寸数', value: product.prtInch + '寸', state: product.isInch });
							}
							if (product.isWeight == 0) {
								result.push({ label: '重量', value: product.prtWeight + 'KG', state: product.isWeight });
							}
							if (product.isEffect == 0) {
								result.push({ label: '效果', value: product.prtEffect, state: product.isEffect });
							}
							res.items[i].infoArr = result;
						}
					}
					this.list = res.items;
					this.$forceUpdate();
					setPageTitle(this.list[this.randomIndex].product.prtName);
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
.video-play-wrapper {
	height: 100%;
	position: relative;
	.my-swipe {
		height: 100%;
	}

	/deep/.van-swipe__indicators {
		display: none; //去掉指示器
	}
}
.footer-content {
	position: relative;
	.add-cart {
		position: absolute;
		bottom: 120px;
	}
	p {
		display: flex;
		flex-direction: row;
		margin-top: 3px;
		color: #fff;
		span:first-child {
			width: 37px;
		}
		span:last-child {
			width: 160px;
		}
	}
}
.right {
	p {
		color: #fff;
		text-align: center;
		font-size: 12px;
		margin-bottom: 24px;
	}
	.home,
	.heart,
	.detail {
		width: 30px;
		height: 30px;
		margin-bottom: 4px;
	}
}

.video-none {
	height: 200px;
	width: 100vw;
	text-align: center;
	font-size: 18px;

	.no-name {
		font-size: 36px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #f7f7f7;
		opacity: 0.3;
		position: absolute;
		top: 300px;
		text-align: center;
		width: 100%;
	}
}
</style>
