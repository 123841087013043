<template>
	<div ref="wrapper" class="video-player-wrapper">
		<video
			ref="video"
			x5-video-player-type="h5"
			webkit-playsinline
			playsinline
			autoplay="autoplay"
			x5-video-player-fullscreen="false"
			preload="auto"
			:src="videoSource"
			class="loaded show"
		/>
		<div class="float-wrapper">
			<div ref="inner" class="inner" @click="switchPlay">
				<div ref="bgImg" class="img-container" :class="{ shrink }" :style="styleObj">
					<!-- 播放按钮 -->
					<img v-show="!isPlay" class="play-btn" src="~@/assets/img/introduction/play.png" alt="" />
					<img class="preview-img" :src="bgImg" alt="" />
					<!--<img class="preview-img" src="~@/assets/img/profile/userCenterBg.png" alt="" />-->
				</div>
				<div ref="info" class="info"><slot name="footer" /></div>
				<!-- 右边插槽 -->
				<div class="control__right" @click.stop=""><slot name="right" /></div>
			</div>
			<!-- 播放进度条 -->
			<div class="control-play">
				<div style="margin-right: 8px">{{ passTime }}</div>
				<div class="progress-bar"><van-slider v-model="value" :max="duration" :min="0" active-color="#fff" @input="onInput" @change="onChange" /></div>
				<div style="margin-left: 8px">{{ remainingTime }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		// true加载视频 false不加载视频 默认加载 可以在父组件中设置为false进行懒加载
		isLoad: {
			type: Boolean,
			default() {
				return true;
			}
		},
		bgImg: {
			type: String,
			default() {
				return '';
			}
		},
		sources: {
			type: String,
			default() {
				return '';
			}
		}
	},
	data() {
		return {
			playBtn: true, // 是否显示图片 图片和video互斥
			videoSource: '', // 视频资源
			isPlay: false, // 当前视频是否正在播放
			value: 0, // slider滑块的值
			currentTime: 0, // 当前播放时间
			duration: 100, // 总时长
			drag: false, // 用户是否在拖拽
			styleObj: {}, // 应用样式
			shrink: false
		};
	},
	computed: {
		// 总时长
		remainingTime() {
			const m = parseInt(this.duration / 60);
			const s = parseInt(this.duration % 60);
			return `${m < 10 ? '0' + m : m}:${s < 10 ? '0' + s : s}`;
		},
		// 已过时长
		passTime() {
			const m = parseInt(this.currentTime / 60);
			const s = parseInt(this.currentTime % 60);
			return `${m < 10 ? '0' + m : m}:${s < 10 ? '0' + s : s}`;
		}
	},
	watch: {
		// 可在父组件控制是否去加载视频资源 swipe滑到当前页的时候设置isLoad为true去加载视频
		isLoad(newValue, oldValue) {
			if (newValue) {
				this.videoSource = this.sources;
			}
		}
	},
	mounted() {
		this.resetDataInfo();
		this.setVideoData();
	},
	methods: {
		resetDataInfo() {
			this.playBtn = false; // 是否显示图片 图片和video互斥
			this.videoSource = ''; // 视频资源
			this.isPlay = true; // 当前视频是否正在播放
			this.value = 0; // slider滑块的值
			this.currentTime = 0; // 当前播放时间
			this.duration = 100; // 总时长
			this.drag = false; // 用户是否在拖拽
			this.styleObj = {}; // 应用样式
			this.shrink = false;
		},
		setVideoData() {
			this.video = this.$refs.video;
			if (this.isLoad) {
				this.videoSource = this.sources;
			}
			this.bindEvent();
		},
		stopVideo() {
			this.$refs.video.pause();
		},
		playVideo(){
			this.$nextTick(() =>{
				this.$refs.video.play();
			})
		},
		resetVideo() {
			// //如果视频已经加载过 重置为初始状态 swipe滑动到下一页的时候调用可重置为视频尚未播放的初始状态
			if (this.isLoad) {
				this.playBtn = true;
				this.isPlay = false;
				this.video.currentTime = 0;
				this.video.pause();
				this.shrink = false;
			}
		},
		bindEvent() {
			this.video.addEventListener('loadedmetadata', () => {
				this.duration = this.video.duration;
			});
			this.video.addEventListener('timeupdate', () => {
				// 解决微信浏览器不能在canplay未正确读取时间长度的bug
				if (this.duration == 0) {
					this.duration = this.video.duration;
				}
				this.currentTime = this.video.currentTime;
				if (!this.drag) {
					this.value = this.video.currentTime;
				}
			});
			this.video.addEventListener('ended', () => {
				this.resetVideo();
			});
		},
		switchPlay() {
			this.playBtn = false;
			this.isPlay = !this.isPlay;
			if (this.isPlay) {
				this.shrink = true;
				this.$refs.video.play();
			} else {
				this.$refs.video.pause();
				this.shrink = false;
			}
		},
		onInput(value) {
			// 拖动的时候设置拖动状态为true
			this.drag = true;
		},
		onChange(value) {
			// 停止拖动的时候设置拖动状态为false
			this.drag = false;
			this.value = value;
			this.video.currentTime = value;
		}
	}
};
</script>

<style lang="less" scoped>
/* 兼容iPhoneX*/
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
	.video-player-wrapper {
		.inner {
			.img-container {
				margin-bottom: 280px;
			}
			.control__right {
				top: 300px;
			}
		}
	}
}

/* 判断ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.video-player-wrapper {
		.inner {
			.img-container {
				margin-bottom: 120px;
			}
			.control__right {
				top: 150px;
			}
		}
	}
}
.inner {
	position: relative;
	flex: 1;
	box-sizing: border-box;
	display: flex;
	align-items: flex-end;
	.img-container {
		position: absolute;
		left: 50%;
		bottom: 50%;
		transform: translate(-50%, 50%);
		/*bottom: 0;*/
		/*right: 0;*/
		/*margin-right: 80px;*/
		/*margin-bottom: 240px;*/
		width: 240px;
		height: 240px;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		transition: all 0.9s;
		.preview-img {
			/*width: 100%;*/
			height: 100%;
		}
	}
	.shrink {
		width: 150px;
		height: 150px;
		position: fixed;
		left: 78%;
		bottom: 110px;
		/*margin-right: 0;*/
		/*margin-bottom: 0 !important;*/
		.play-btn {
			display: none;
		}
	}
	.info {
		position: absolute;
		width: 200px;
		margin-left: 11.5px;
		color: #fff;
	}
	.control__right {
		position: absolute;
		// top: 224.5px;
		top: 50%;
		transform: translate(-10%, -50%);
		right: 21.5px;
		text-align: center;
	}
}
.play-btn {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 55px;
	z-index: 3;
}
.video-player-wrapper {
	height: 100%;
	display: flex;
	align-items: center;
	overflow: hidden;
	background-color: rgba(0, 0, 0);
	video {
		width: 100%;
	}
	.float-wrapper {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	//播放进度条
	.control-play {
		padding: 0 12px;
		height: 60px;
		display: flex;
		align-items: center;
		color: #fff;
		.progress-bar {
			flex: 1;
			margin: 0 8px;
		}
	}
	/deep/.van-slider__button {
		width: 16.5px;
		height: 16.5px;
	}
	/deep/.van-slider {
		background: #666;
	}
	video::-webkit-media-controls-enclosure {
		display: none !important;
	}
}
video::-webkit-media-controls-fullscreen-button {
	display: none;
}
//播放按钮
video::-webkit-media-controls-play-button {
	display: none;
}
//进度条
video::-webkit-media-controls-timeline {
	display: none;
}
//观看的当前时间
video::-webkit-media-controls-current-time-display {
	display: none;
}
//剩余时间
video::-webkit-media-controls-time-remaining-display {
	display: none;
}
//音量按钮
video::-webkit-media-controls-mute-button {
	display: none;
}
video::-webkit-media-controls-toggle-closed-captions-button {
	display: none;
}
//音量的控制条
video::-webkit-media-controls-volume-slider {
	display: none;
}
//所有控件
video::-webkit-media-controls-enclosure {
	display: none;
}
</style>
